/*
*******************************************
Template Name: ACA Benefits
Author: Anush
Date: 19-09-2023
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************
*/


.SPR_V9 {
    .modal{
        background: #000000d1;
    }
    html {
        scroll-behavior: smooth;
    }

    body {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }


    .__ACAhome {
        font-family: 'Roboto', sans-serif;

        header {
            background: linear-gradient(252deg, #ff512f, #f09819 -4%, #ff512f);
            padding: 15px 0;

            .top_notch {
                max-width: 1130px;
                padding: 0 20px;

                .nav_barACA {
                    display: flex;
                    align-items: center;

                    .img_slot {
                        img {
                            height: 45px;
                            transition: all ease-in 0.3s;
                            -webkit-transition: all ease-in 0.3s;
                            -moz-transition: all ease-in 0.3s;
                            -o-transition: all ease-in 0.3s;
                        }

                        //img
                    }

                    //img_slot
                }

                //nav_barACA
                .nav_btnACA {
                    text-align: end;

                    .btn_clickcall {
                        button {
                            background-color: #fff;
                            color: #ff512f;
                            text-decoration: none;
                            padding: 12px 28px;
                            font-weight: 700;
                            border-color: transparent;
                            border-width: 1px;
                            border-style: solid;
                            letter-spacing: 0;
                            text-transform: capitalize;
                            text-shadow: 0 0 0 transparent;
                            width: auto;
                            border-radius: 15px;
                            font-family: 'Montserrat', sans-serif !important;
                        }

                        .btn_sm_delight {
                            animation: pulseGlow 2s infinite;
                            animation-timing-function: ease-in-out;
                        }

                        @keyframes pulseGlow {
                            0% {
                                box-shadow: 0 0 #fff0
                            }

                            25% {
                                box-shadow: 0 0 2.5px 1px #ffffff40
                            }

                            50% {
                                box-shadow: 0 0 5px 2px #ffffff80
                            }

                            85% {
                                box-shadow: 0 0 5px 5px #fff0
                            }

                            to {
                                box-shadow: 0 0 #fff0
                            }
                        }

                        //pulseGlow
                    }

                    //btn_clickcall
                }

                //nav_btnACA
            }

            //top_notch
        }

        //header


        .hl_page-preview--content {
            max-width: 1170px;

            .main_bdy {
                padding: 0px 90px;

                .fullSection {
                    padding: 0 150px 10px;

                    .inner {
                        padding: 20px 0 0;

                        .pre_contentH1 {
                            padding: 10px 10px 15px;

                            h1 {
                                font-size: 43px;
                                font-weight: 700;
                            }

                            //h1
                            p {
                                padding: 0;
                                margin: 0 0 2px 0;
                                display: flex;

                                img {
                                    height: 100%;
                                }

                                .sml-note {
                                    font-size: 15px;
                                    padding: 0;
                                    margin: 0;
                                    font-weight: 600;
                                    color: #010101;
                                }

                                //sml-note
                            }

                            //p
                        }

                        //pre_contentH1

                        .pre_btn_dialNum {
                            .custom_callBtn {
                                box-shadow: 0 0 14px -7px #f09819;
                                background: linear-gradient(45deg, #ff512f, #f09819 51%, #ff512f);
                                cursor: pointer;
                                color: #fff;
                                text-decoration: none;
                                padding: 10px 20px;
                                font-weight: 700;
                                font-size: 21px;
                                border-width: 2px;
                                border-style: none;
                                letter-spacing: 0;
                                text-transform: none;
                                text-shadow: 0 0 0 transparent;
                                width: 100%;
                            }

                            //custom_callBtn
                            .buttonBounce {
                                animation: bounce 1.5s infinite;
                                animation-timing-function: ease-in;
                                transition: .2s;
                                -webkit-animation: bounce 1.5s infinite;
                            }

                            //buttonBounce
                            @keyframes bounce {
                                15% {
                                    box-shadow: 0 0 0 0 transparent;
                                    transform: translateY(0)
                                }

                                35% {
                                    box-shadow: 0 8px 5px -5px #00000040;
                                    transform: translateY(-35%)
                                }

                                45% {
                                    box-shadow: 0 0 0 0 transparent;
                                    transform: translateY(0)
                                }

                                55% {
                                    box-shadow: 0 5px 4px -4px #00000040;
                                    transform: translateY(-20%)
                                }

                                70% {
                                    box-shadow: 0 0 0 0 transparent;
                                    transform: translateY(0)
                                }

                                80% {
                                    box-shadow: 0 4px 3px -3px #00000040;
                                    transform: translateY(-10%)
                                }

                                90% {
                                    box-shadow: 0 0 0 0 transparent;
                                    transform: translateY(0)
                                }

                                95% {
                                    box-shadow: 0 2px 3px -3px #00000040;
                                    transform: translateY(-2%)
                                }

                                99% {
                                    box-shadow: 0 0 0 0 transparent;
                                    transform: translateY(0)
                                }

                                to {
                                    box-shadow: 0 0 0 0 transparent;
                                    transform: translateY(0)
                                }
                            }

                            //@keyframes bounce
                        }

                        //pre_btn_dialNum




                        .img_bgPic {
                            width: 100%;

                            img {
                                width: 100%;
                            }
                        }

                        //img_bgPic

                        .coverage_contentLk {
                            .base_clr {
                                padding-top: 10px;
                                margin-bottom: 10px;
                                color: #ff512f;
                                font-size: 14px;
                                font-weight: 600;
                            }

                            //base_clr
                            .__texNote {
                                font-size: 16px;
                                margin-bottom: 10px;

                                .change_clrBase {
                                    font-weight: 600;
                                    color: #ff512f;
                                }

                                //change_clrBase
                            }

                            //__texNote
                        }

                        //coverage_contentLk


                        .card_sec {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 10px;
                            justify-content: center;
                            padding: 15px 0;

                            .point_lokCrzy {
                                width: 30%;
                                display: grid;
                                text-align: center;
                                align-items: center;
                                justify-items: center;
                                padding: 11px;
                                border-radius: 15px;
                                height: 140px;
                                box-shadow: 0px 10px 37px -4px rgba(0, 0, 0, 0.1);

                                img {
                                    padding: 0 0 10px;
                                }

                                span {
                                    color: #141414;
                                    font-size: 14px;
                                    font-weight: 600;
                                }

                                //span
                                .tiny_txt {
                                    color: #383838;
                                    font-size: 12px;
                                }

                                //tiny_txt
                            }

                            //point_lokCrzy
                        }

                        //card_sec


                    }

                    //inner
                }

                //fullSection
            }

            //main_bdy
        }

        //hl_page-preview--content


        .qspart {
            min-height: 160px;

            .text1 {
                color: #fe552e;
            }

            .qs__item {
                h2 {
                    font-size: 1.3rem;
                }
            }
        }

        .btn-comm {
            background: #fe552e;
            padding: 15px 10px;
            color: white;
            outline: none;
            box-shadow: none;
            border: none;
            border-radius: 5px;
            transition: ease-in 0.2s;

            &.hover {
                &:hover{
                    transform: translateY(-5px);
                    transition: ease-in 0.2s;
                    background: #ff6b4a;
                }
            }

        }

        .sticky-header {



            &.sticky {
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 1000;
                padding: 5px 0;
                transition: all ease-in 0.3s;
                -webkit-transition: all ease-in 0.3s;
                -moz-transition: all ease-in 0.3s;
                -o-transition: all ease-in 0.3s;

                .top_notch {
                    .nav_barACA {
                        .img_slot {
                            img {
                                height: 35px;
                                transition: all ease-in 0.2s;
                                -webkit-transition: all ease-in 0.2s;
                                -moz-transition: all ease-in 0.2s;
                                -o-transition: all ease-in 0.2s;
                            }
                        }
                    }
                }

            }
        }


        /*================================================
05 - FORM SECTION
==================================================*/

        .slide_sec {
            margin: 0px 0px 30px;

            .slide_bg1 {
                margin-top: 0;
                margin-bottom: 0;
                padding: 0 100px 10px;


                .form_sec {
                    background-color: #f2f2f2;
                    padding: 30px 30px 20px;

                    h2 {
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 1.2em;
                        text-align: center;
                        margin-top: 10px;
                        margin-bottom: 20px;
                        text-shadow: 2px 2px 5px #999999;
                    }

                    h5 {
                        color: #999999;
                        font-size: 20px;
                        text-align: center;
                        margin-bottom: 20px;
                        font-weight: 500;
                    }

                    .icon_option {
                        width: 100%;
                        margin-bottom: 20px;
                        font-size: 24px;
                        padding: 15px 20px;
                        text-align: center;
                        transition: 0.5s;
                        background-size: 200% auto;
                        color: white;
                        border-radius: 1px;
                        display: block;
                        border: 0px;
                        font-weight: 600;
                        box-shadow: 0px 0px 14px -7px #f09819;
                        background-image: linear-gradient(45deg,
                                #ff512f 0%,
                                #f09819 51%,
                                #ff512f 100%);
                        cursor: pointer;
                        user-select: none;
                        -webkit-user-select: none;
                        touch-action: manipulation;

                        &:hover {
                            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
                                rgba(0, 0, 0, 0.23) 0px 6px 6px;

                            transform: translateY(-1px);
                        }

                        span {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        .sctop1 {
                            display: none;
                        }
                    }

                    .slid_img1 {
                        width: 100%;
                        height: auto;
                        margin-bottom: 20px;
                    }

                    .slid_txt1 {
                        font-size: 20px;
                        font-weight: 700;
                    }

                    .slid_txt2 {
                        color: #4f4f4f;
                        font-size: 10px;
                        padding: 25px;
                        text-align: center;
                    }

                    .icon_option_num {
                        margin: 0 auto;
                        margin-bottom: 20px;
                        font-size: 24px;
                        padding: 20px 30px;
                        text-align: center;
                        background-size: 200% auto;
                        color: white;
                        border-radius: 1px;
                        display: block;
                        border: 0px;
                        font-weight: 600;
                        box-shadow: 0px 0px 14px -7px #f09819;
                        background-image: linear-gradient(45deg,
                                #ff512f 0%,
                                #f09819 51%,
                                #ff512f 100%);
                        cursor: pointer;
                        user-select: none;
                        -webkit-user-select: none;
                        touch-action: manipulation;
                        animation: 2s cubic-bezier(0, 0, 0.58, 1) infinite rocking;
                        transition: 0.2s;

                        @keyframes rocking {

                            0%,
                            25%,
                            to {
                                transform: rotate(0);
                            }

                            50% {
                                transform: rotate(2deg);
                            }

                            75% {
                                transform: rotate(-2deg);
                            }
                        }

                        span {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        .sctop1 {
                            display: none;
                        }
                    }

                    .back_btn {
                        margin: 0px 0px 15px;
                        padding: 0px 0;
                        color: #535353 !important;
                        font-weight: 400;
                        font-size: 16px;
                        background: transparent;
                        width: 100%;
                        border: none;
                    }
                }

                #myDiv {
                    display: none;

                    .text11 {
                        font-size: 24px;
                        animation: 3s ease forwards 1 fadein;
                        opacity: 0
                    }

                    @keyframes fadein {
                        from {
                            opacity: 0
                        }

                        to {
                            opacity: 1
                        }
                    }

                }

                #slide_3,
                #slide_4 {
                    display: none;
                }

                #load1,
                #load2,
                #load3 {
                    display: none;
                }



            }
        }

        .faq__accordion {
            h3 {
                margin-left: auto;
                margin-right: auto;
                width: fit-content;
                font-weight: bold;
            }
            .faq__accordion-item {
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                margin-bottom: 10px;

                &.active {
                    .faq__accordion-content {
                        display: block;
                        transition: all ease-in 0.5s;
                    }


                    .faq__accordion-title {
                        color: #fe552e;
                       

                        .icon {
                            background-position: 0 -18px;
                        }
                    }

                    .faq__accordion-content {
                        opacity: 1;
                        transition: ease-in 0.8s;

                        animation: mymove 1s;
                    }

                    @keyframes mymove {
                        0% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }


                }

            }

            .faq__accordion-title {
                .accordion-button::after {
                    display: none;
                }
                button:focus:not(:focus-visible) {box-shadow: none;}
                .accordion-button:not(.collapsed) {background: transparent; border-bottom: 1px solid #ddd; color: #fe552d;
                    }
                font-size: 1rem;
                color: #333;
                cursor: pointer;
                padding: 5px;
                font-weight: bold;
                position: relative;

                &:hover {
                    color: #fe552e;
                }
                .accordion-button {
                    font-weight: bold;
                    .icon {
                        position: absolute;
                        width: 18px;
                        height: 15px;
                        background: url("../../img/SPR_V9/plus-icon.png") no-repeat;
                        background-size: 15px !important;
                        right: 8px;
                        top: 18px;
                        

                        background-position: center -20px;
    
                    }


                    &.collapsed {                 
                .icon {
                    
                    background: url("../../img/SPR_V9/plus-icon.png") no-repeat;
                    

                }
                

            }
        }
            }

            .faq__accordion-content {
                display: none;
                padding: 20px;
                border-top: 1px solid #ccc;
                opacity: 0;
            }


        }

        .list-items {
            li {
                &::marker {
                    content: "☆";
                    display: inline-block;
                    color: #fe552e;
                    font-size: 1.3rem;
                    //background: url("../img/list-icon.png");
                    width: 10px;
                    height: 10px;
                }

                padding-left: 10px;
                margin-bottom: 5px;
            }
        }

        .carousel-container {
            position: relative;

            margin: 0 auto;

            .carousel-wrapper {
                display: flex;
                overflow: hidden;
                transition: transform 0.5s ease;
                /* Add transition for smooth animation */
            }

            .carousel-slide {
                flex: 0 0 auto;
                width: 100%;
            }

            .carousel-slide img {
                width: 100%;
                height: auto;
                display: block;
            }

        }








        /*================================================
  05 - FORM SECTION END
  ==================================================*/




        footer {
            box-shadow: -5px -12px 34px -4px rgba(0, 0, 0, 0.1);

            p {
                font-size: 14px;

                .foot {
                    text-decoration: underline;
                    color: blue;
                }

                a {
                    text-decoration: none;
                    color: unset;
                }
            }
        }

        //footer


        @media screen and (max-width: 780px) {
            .main_bdy {
                padding: 0px !important;

                .fullSection {
                    padding: 0px !important;
                }
            }

            .point_lokCrzy {
                width: 45% !important;
            }

        }

        @media screen and (min-width: 0px) and (max-width: 480px) {
            .btn_clickcall {
                button {
                    padding: 6px 20px !important;
                }
            }

            .point_lokCrzy {
                width: 48% !important;
            }

            .mob_Wd100 {
                width: 100% !important;
            }

            .img_slot {}

            .pre_contentH1 {
                padding: 0px 0px 20px !important;
            }

            .inner {
                padding: 20px 0 !important;
            }

            h1 {
                font-size: 24px !important;
            }

            .slide_sec {
                .slide_bg1 {
                    padding: 0px;

                    .form_sec {
                        padding: 20px 15px 1px;

                        h2 {
                            font-size: 20px;
                        }

                        h5 {
                            font-size: 16px;
                            margin-bottom: 20px;
                        }

                        .icon_option {
                            font-size: 16px;
                        }

                        .icon_option_num {
                            font-size: 16px;
                        }
                    }
                }
            }

            .review-img {
                img {
                    height: 30px;
                }
            }

            .faq__accordion {
                .faq__accordion-title {
                    font-size: .9rem;
                    padding: 5px;
                  
                }

                .faq__accordion-content {
                    font-size: .9rem;

                }
            }

            .qspart {
                height: auto;
                min-height: 150px;

                .qs__item {
                    h2 {
                        font-size: 1.1rem;
                    }
                }

            }

        }

        .show {
            display: block;
        }

        .hide {
            display: none;
        }


    }

    //__ACAhome
}

//ACA_SPH_V9