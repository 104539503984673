@import 'custom';
.SPR_WA_V11{


 header {
  background:  #ffffff;
 }


.chat-section {
  width: 100%;
  float: left;
  .chat-wrap {
    max-width: 650px;
    margin: 0 auto;
  }
  
  .chat-box-inner {
    position: relative;
    z-index: 5px;
    min-height: 400px;
    padding: 10px;
    overflow: hidden;
    .row-chart-message {
      font-family: Arial, Helvetica, sans-serif;
      .chater-prof {
        display: flex;
        align-items:flex-end;
        max-width: 90%;
        .text-msg {
          background: #e5e7eb;
          padding: 10px 15px;
          margin:5px 0 0 0;
          display: inline-block;
          border-radius: 5px;
          font-size: 15px;
          cursor: pointer;

          &.btn-spc {
            padding: 20px 10px;
          }
        }

        .phone {
          font-size: 19px;
          padding: 13px 30px;
        }

      }

      .msg-loader {
        img {
          height: 15px;
          
        }
      }
    }
    .supporter-dp {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ddd;
      display: block;
      margin-right: 10px;
      text-align: center;
      border: 1px solid #ddd;
      overflow: hidden;
      img {
 
        height: 38px;
       
        
      }
    }
    .sel-option {
         
       
      .action-btn {
        background:   #3b82f6;
        padding:15px 40px  ;
        color: #fff;
        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        border-radius: 50px;
        font-weight: bold;
        &.answ-bnt {
          padding: 15px 20px;
        }
       
      }
    }

    .client-replay {
      text-align: right;
      margin-top: 10px;

      .client-replay-vpx {
        display: flex;
        justify-content: end;
        align-items: center;
          .dp-client {
            @extend .supporter-dp;
            order: 2;
            margin-right: 0;
            margin-left: 10px;
            
          }

      }
  }
  }

 }

 .list-items {
   
  li {
    font-size: 16px;
    position: relative;
    list-style-type: none;
    padding: 4px 0;
    &::before {
      content: "";
      background: url("../../img/SPR_WA_V11/index-icon.png") no-repeat center center;
      width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      left: -40px;
      top: 0;
    }
  }
 }

 .btn_reClaim {
  width: 85%;
  background: #00a700 !important;
  color: #fff;
  font-weight: 700 !important;
  border: 0 !important;
}

.btn-anime {
  box-shadow: 0 0 0 0 rgba(90,153,212,.5);
  animation: btn_anime_glowing 1.5s cubic-bezier(.57,.31,.36,1) infinite;
}

@keyframes btn_anime_glowing {
    0% {
      transform: scale(.9);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 30px rgba(90,212,110,0);
    }
    100% {
      transform: scale(.9);
      box-shadow: 0 0 0 0 rgba(90,212,110,0);
    }
}


.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
  }
.body-inner {
  height: 100vh;
}

footer {
  float: left;
  width: 100%;
  padding-top: 150px;
  p {
    font-size: 13px;
  }
  a {
    color: #000;
    text-decoration: none;
  }
}
.text1 {
   font-family: Georgia, 'Times New Roman', Times, serif;
   font-size: 18px;
   font-weight: bold;
   color: #4b5563;
}
.text2 {
  font-size: 28px;
  color: #4b5563;
}
.support-online {
   span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
    display: inline-block;
    position: relative;
    top: 3px;
     background: #22c55e;
  }
   

  .pulse {
    animation: pulse-animation 1.5s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(162, 221, 122, 0.616);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  
  
}


  @media screen and (max-width: 760px) {
       
  
    .chat-section {
      .chat-box-inner {
        .sel-option {
          .action-btn {
            padding: 15px 30px;
          }
        }
      }
    }

    .text1 {
      font-size: 14px;
    }
    .text2{
      font-size: 26px;
    }
    
  }

  @media screen and (max-width: 490px) {
    .text2 {
      font-size: 26px;
  }
    .list-items  {
      li {
        padding-left: 35px;
        line-height: 20px;
        &::before {

          
          left: 0;
          background-size: 20px 20px;
        }
      }
    }
  }
}






