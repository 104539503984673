.ACA_BQ_2 {
  .d-block {
    display: block;
  }

  .d-none {
    display: none;
  }

  /*
*******************************************
Template Name: ACA BENEFITS QUIZ
Author: Allen Mathew
Date: 27-06-2023
Template By: Vandalay Designs
Copyright 2023 Vandalay Business Solution

* This file contains the styling for the Personal-Injury, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - SLIDE SECTION
05 - PROGRESS BAR SECTION
06 - FOOTER SECTION
07 - MEDIA QUERY


============================================
============================================

*/

  /*================================================
01 - Sass Variables
==================================================*/

  $background_color_1: #fff;
  $background_color_2: #dddddd;

  $color_1: #fff;
  $color_2: #e65100;

  $font-bold: bold;
  $font-w700: 700;
  $font-w600: 600;
  $font-w400: 400;

  $font_size_1: 16px;
  $font_size_2: 14px;

  // ======border===========
  $border-style1: 2px solid #d9d9d9;

  /*================================================
02 - Sass Mixins
==================================================*/

  /*================================================
03 - BODY STYLES
==================================================*/

  body {
    font-family: "Poppins", sans-serif !important;
    scroll-behavior: smooth;
  }

  header {
    text-align: center;
    min-height: 100px;
    background-color: #ffffff;

    img {
      padding-top: 20px;
      width: 180px;
    }
  }

  .foot {
    color: blue !important;
    text-decoration: underline;
  }

  .loader-gif {
    filter: hue-rotate(183deg);
  }

  /*================================================
04 - SLIDE SECTION
==================================================*/
  .slide_sec {
    margin: 30px 0px;
    min-height: 70vh;

    .slide_bg1 {
      margin-top: 0;
      margin-bottom: 0;

      .call {
        color: #ffffff !important;
        text-decoration: none !important;
      }

      .form_sec {
        background-color: #f2f2f2;
        padding: 30px;

        h2 {
          font-size: 33px;
          font-weight: 700;
          line-height: 1.2em;
          text-align: center;
          margin-top: 10px;
          margin-bottom: 20px;
          text-shadow: 2px 2px 5px #999999;
        }

        h5 {
          color: #999999;
          font-size: 21px;
          text-align: center;
          margin-bottom: 40px;
          font-weight: 500;
        }

        .icon_option {
          width: 100%;
          margin-bottom: 20px;
          font-size: 24px;
          padding: 15px 20px;
          text-align: center;
          transition: 0.5s;
          background-size: 200% auto;
          color: white;
          border-radius: 10px;
          display: block;
          border: 0px;
          font-weight: 600;
          box-shadow: 0px 0px 14px -7px #f09819;
          background-image: linear-gradient(45deg,
              #81b9ff 0%,
              #005ce5 51%,
              #006eff 100%);
          cursor: pointer;
          user-select: none;
          -webkit-user-select: none;
          touch-action: manipulation;

          &:hover {
            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
              rgba(0, 0, 0, 0.23) 0px 6px 6px;

            transform: translateY(-1px);
          }

          span {
            display: inline-block;
            vertical-align: middle;
          }

          .sctop1 {
            display: none;
          }
        }

        .slid_img1 {
          width: 100%;
          height: auto;
          margin-bottom: 20px;
        }

        .slid_txt1 {
          font-size: 20px;
          font-weight: 700;
        }

        .slid_txt2 {
          color: #4f4f4f;
          font-size: 10px;
          padding: 25px;
          text-align: center;
        }



        .icon_option_num {
          width: 50%;
          margin: 0 auto;
          margin-bottom: 20px;
          font-size: 24px;
          padding: 20px 30px;
          text-align: center;
          background-size: 200% auto;
          color: white;
          border-radius: 10px;
          display: block;
          border: 0px;
          font-weight: 600;
          box-shadow: 0px 0px 14px -7px #f09819;
          background-image: linear-gradient(45deg,
              #5da5ff 0%,
              #005ce5 51%,
              #006eff 100%);
          cursor: pointer;
          user-select: none;
          -webkit-user-select: none;
          touch-action: manipulation;
          animation: 2s cubic-bezier(0, 0, 0.58, 1) infinite rocking;
          transition: 0.2s;

          @keyframes rocking {

            0%,
            25%,
            to {
              transform: rotate(0);
            }

            50% {
              transform: rotate(2deg);
            }

            75% {
              transform: rotate(-2deg);
            }
          }

          span {
            display: inline-block;
            vertical-align: middle;
          }

          .sctop1 {
            display: none;
          }
        }

        .icon_option_num_a {
          width: 44%;
          letter-spacing: 4px;
          font-size: 25px;
          padding: 17px 7px;

          img {
            width: 26px;
          }
        }

        .back_btn {
          margin: 15px 0px 0px;
          padding: 0px 0;
          color: #535353 !important;
          font-weight: 400;
          font-size: 16px;
          background: transparent;
          width: 100%;
          border: none;
        }

        .txt_clr {
          color: red;
          font-size: 24px;
        }
      }

      .spanmb0 {
        margin-bottom: 10px !important;
      }

      .spanmb1 {
        margin-bottom: 40px !important;
      }

      #myDiv {
        display: none;

        .text11 {
          font-size: 24px;
          animation: 3s ease forwards 1 fadein;
          opacity: 0
        }

        @keyframes fadein {
          from {
            opacity: 0
          }

          to {
            opacity: 1
          }
        }

      }

      #slide_2,
      #slide_3,
      #slide_4 {
        display: none;
      }
    }
  }

  /*================================================
06 - FOOTER SECTION
==================================================*/

  footer {
    background-color: #ffffff;
    color: #000000;
    text-align: center;
    position: relative;
    width: 100%;
    bottom: 0;
    padding-top: 30% !important;

    img {
      width: 180px;
    }

    p {
      font-size: 14px;

      a {
        text-decoration: none;
        color: #000000 !important;
      }
    }


  }

  /*================================================
7 - MEDIA QUERY
==================================================*/

  $break-ipad-pro: 1024px;
  $break-min-ipad: 768px;
  $break-max-ipad: 992px;
  $break-min-tab: 577px;
  $break-max-tab: 767px;
  $break-mob: 576px;

  @media screen and (min-width: 2300px) {
    footer {
      position: absolute !important;
      width: 80% !important;
      bottom: 0;
      padding-top: 20% !important;
    }
  }

  @media screen and (max-width: $break-max-ipad) {
    .slide_sec {
      .form_sec {
        .icon_option_num {
          width: 70% !important;
          font-size: 20px !important;
        }

        .icon_option {
          font-size: 20px !important;
        }
      }
    }
  }

  @media screen and (max-width: $break-min-ipad) {
    header {
      .header_txt {
        p {
          padding: 0px !important;
          margin: 0px !important;
          font-size: 14px !important;
        }
      }
    }

    footer {
      text-align: center;
      padding-top: 20% !important;
    }
  }

  @media screen and (max-width: $break-mob) {
    header {
      img {
        width: 120px !important;
      }

      .header_txt {
        p {
          padding: 0px !important;
          margin: 0px !important;
          font-size: 9px !important;
        }

        h1 {
          padding: 0px !important;
          margin: 0px !important;
          font-size: 20px !important;
        }
      }
    }

    .slide_sec {
      margin: 0px 0px !important;
      min-height: 100% !important;

      .form_sec {
        .slid_txt2 {
          padding: 0px !important;
        }

        h2 {
          font-size: 22px !important;
          margin-bottom: 30px;
        }

        h5 {
          font-size: 18px !important;
        }

        .icon_option {
          font-size: 16px !important;
        }

        .icon_option_num {
          font-size: 16px !important;
          width: 86% !important;
          padding: 20px 10px !important;
        }

        .icon_option_num_a {
          font-size: 18px !important;

          img {
            width: 18px !important;
          }
        }

        .back_btn {
          font-size: 14px !important;
        }

        .txt_clr {
          color: red;
          font-size: 24px;
        }
      }

      #myDiv {

        .text11 {
          font-size: 20px !important;
          margin-bottom: 50px !important;
        }



      }
    }

    footer {
      text-align: center;
      padding-top: 80% !important;

      img {
        width: 120px !important;
      }



      p {
        font-size: 12px !important;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .slide_sec {
      .form_sec {
        .icon_option_num {
          width: 86% !important;
          font-size: 14px !important;
          padding: 20px 10px !important;
        }

        .icon_option_num_a {
          font-size: 18px !important;

          img {
            width: 20px !important;
          }
        }
      }
    }

    footer {

      padding-top: 80% !important;
      p {
        font-size: 10px !important;
      }
    }
  }
}