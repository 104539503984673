/*
*******************************************
Template Name: Seguroparalatinos
Author: Ramees
Date:20-11-2023
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - Questionnaie 

08 - FOOTER 
09 - MODAL 
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/

*,
::after,
::before {
	box-sizing: border-box;
}


* {
	outline: none;
}

html {
	scroll-behavior: smooth;
}

.SSPR_ADV1 {
	@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

	@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

	@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@100;200;300;400;500;600;700;800;900&display=swap');


	$background_color_1: #ffffff;
	$background_color_2: #32CD32;
	$background_color_3: #0b334a;
	$background_color_4: #ffc248;
	$background_color_5: #f7f7f7;
	$background_color_6: #00a100;


	$color_1: #ffc248;
	$color_2: #1f1f1f;
	$color_3: #ffffff;
	$color_4: #32CD32;
	$color_5: #ff0000;
	$color_6: #000000;
	$font-family_1: 'Poppins', sans-serif;
	$font-family_2: 'Overpass', sans-serif;

	font-family: "Overpass", sans-serif !important;
	color: #1f1f1f !important;
	min-height: 100vh;
	background-color: #e8f4f2 !important;

	// ======border===========

	/*================================================
02 - Sass Mixins
==================================================*/

	@mixin shadow-1 {
		-webkit-box-shadow: 0px 0px 23px -6px rgba(207, 207, 207, 1);
		-moz-box-shadow: 0px 0px 23px -6px rgba(207, 207, 207, 1);
		box-shadow: 0px 0px 23px -6px rgba(207, 207, 207, 1);
	}

	/*================================================
	BODY STYLES
==================================================*/


	a {
		cursor: pointer;
		text-decoration: none;
		color: unset;
		color: $color_6 !important;
	}

	em {
		font-style: normal;
	}

	.br-50 {
		border-radius: 50px;
	}


	p {
		font-size: 18px;
	}

	.green-text{
		color: $color_4;
	}
	// background-color: #e8f4f2 !important;


	.main_head {
		background-color: $background_color_2;
		color: white;
		padding: 25px;

		.head_foot {
			width: 32rem;
			margin: auto;
			padding: 0 15px;

			h1 {
				font-weight: 700;
				line-height: 120%;
				font-size: 32px;

				span {
					font-size: 24px;
					font-weight: 600;
					padding-left: 33px;
				}
			}

			.btn-call {
				text-decoration: none;

				button {
					border: none;
					background: $background_color_1;
					width: 100%;
					height: 40px;
					font-weight: 900;

					&:hover {
						background-color: $background_color_5;
					}
				}
			}
		}
	}

	.paralatinos {
		margin: auto;
		width: 32rem;
		padding: 10px 15px 20px 15px;

		.elementory_sec {
			.dlr {
				color: $color_4;
				font-size: 36px;
				font-weight: 800;
				text-shadow: 3px 3px 2px $color_3;
				position: relative;
				z-index: 1;

				&::after {
					content: "";
					background: url(../../img/SSPR_ADV1/line.png);
					background-repeat: no-repeat;
					position: absolute;
					z-index: -1;
					top: 20%;
					bottom: 0;
					left: 15%;
					right: 0;
					width: 100%;
					animation: scale 1s ease-in-out infinite;
					;
				}

				@keyframes scale {
					0% {
						width: 0;
					}

					25% {
						width: 20%;
					}

					50% {
						width: 35%;
					}

					75% {
						width: 50%;
					}

					100% {
						width: 100%;
					}
				}
			}

			.resuelto {
				color: $color_4;
			}

			.btn-div {
				border: 5px solid $color_4;
				padding: 0;

				h5 {
					background-color: $background_color_2;
					margin-bottom: 32px;
					color: $color_3;
					position: relative;

					&::after {
						content: " ";
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 20px 20px 20px 0;
						border-color: transparent #32cd32 transparent transparent;
						position: absolute;
						z-index: 1000;
						top: 75%;
						left: 52%;
						transform: translateY(-50%);
						rotate: -90deg;
					}
				}

				.age-btn {
					text-decoration: none;
					span.txt_unerline{
						font-weight: bold;
						text-decoration: underline !important;
					}
					button {
						border: none;
						width: 100%;
						font-size: 18px;
						font-weight: 700;
						background: $background_color_2;
						color: $color_3;
						padding: 15px 5px;

						&:hover {
							background: $background_color_6;
						}
					}
				}
			}

			.Cómo {
				p {
					span {
						// color: $color_4;
					}
				}
			}

			.calificar {
				display: flex;
				align-items: center;
				text-align: left;
				padding-bottom: 10px;
			}
		}
	}

	//Spanish_ACAques





	/*================================================
06 - FOOTER SECTION
==================================================*/

	footer {
		background-color: #ffffff;
		color: #000000;
		position: relative;
		width: 100%;
		padding: 90px 0 50px;
		bottom: 0;
		box-shadow: -5px -12px 34px -4px rgba(0, 0, 0, .1);

		p {
			font-size: 14px;

			a.click_link {
				text-decoration: none;
				color: #0d6efd;
			}

			a {
				text-decoration: none;
				color: #000000;
			}
		}

		// FOOTER P
	}

	//FOOTER

	/*================================================
	MEDIA QUERY
==================================================*/

	@media screen and (max-width: 768px) {
		.main_head {
			padding: 10px;

			h1 {
				font-size: 24px !important;
				text-align: left;

				span {
					font-size: 18px !important;
				}
			}

			// h1
		}
	}

	@media screen and (max-width: 530px) {
		p {
			font-size: 16px !important;
		}

		.paralatinos {
			width: 100% !important;
			padding-left: 10px;
			padding-right: 10px;
		}

		.head_foot {
			width: 100% !important;
			padding: 0 !important;

			.btn-call {
				button {
					img {
						width: 70%;
					}

					h1 {
						font-size: 26px !important;
						display: flex;
						align-items: center;
						align-content: center;
						justify-content: center;
						margin: 0;
					}
				}
			}
		}
	}

	@media screen and (max-width: 460px) {
		.head_foot {
			h1 {
				font-size: 19px !important;
				text-align: center !important;

				span {
					font-size: 17px !important;
					padding: 10px !important;
				}
			}
		}

		.dlr {
			&::after {
				left: 8% !important;
			}
		}
	}

	@media screen and (max-width: 390px) {
		.dlr {
			&::after {
				left: 0 !important;
			}
		}
	}

	@media screen and (min-width: 1500px) {
		.paralatinos {
			min-height: 80vh !important;
		}
	}

	@media screen and (min-width: 2500px) {
		.paralatinos {
			min-height: 90vh !important;
		}
	}
}