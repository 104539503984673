/*
*******************************************
Template Name: charlottestips
Author: Allen Mathew
Date: 30-10-2023
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for laonclaims.co.uk, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - SECTION 
06 - FOOTER 
07 - MODAL 
08 - MEDIA QUERY

============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

// =======background colors========

$background-color-1: #ffec51;


//======= colors==========

$color-1: #ffffff;


// ======font styles===============

$font-size-1: 22px;


$font-weight-1: 600;

// ======border===========

/*================================================
02 - Sass Mixins
==================================================*/

/*================================================
03 - BODY STYLES
==================================================*/

html {
  scroll-behavior: smooth;
}

body {
  // font-family: 'Inter', sans-serif !important;

  .SPH_CDV1 {
    
    .d-block{
      display: block;
    }
    .d-none{
      display: none;
    }
    /*================================================
04 - HEADER
==================================================*/
    header {
      .logo_main1 {
       width: 45%;
        margin-top: 20px;
      }

      .logo-main-1 {
        height: 80px;
        margin-top: 20px;
      }

      .hdr_imgs {
        display: flex;
        justify-content: end;
        align-items: center;

        .union-flag {
          height: 65px;
          padding-right: 10px;
        }


      }

    }

    /*================================================
05 - MAIN SECTION
==================================================*/


    .banner_section {
      .main_sec {
        padding-right: 40px;

        .yellow_head {
          font-size: 17px;
          margin-top: 30px;

          span {
            background-color: $background-color-1;
            padding: 6px 6px;
            border-radius: 6px;
          }
        }

        .Obamacare_Logo {
          height: 70px;
          margin-bottom: 20px;
        }

        h2 {
          margin-top: 50px;
          font-size: 28px;
          line-height: 140%;
          color: #0E1428;
          font-weight: 600;

          .highlight_circle {
            position: relative;
            z-index: 5;
            display: inline-block;
            font-weight: 800;
            z-index: -1;
            white-space: nowrap;

            svg {
              position: absolute;
              top: -20%;
              left: -5%;
              width: 110%;
              height: inherit;
              fill: #FFC107;
              opacity: 0.50;
              z-index: -1;
              -webkit-animation: in-out 1.25s infinite alternate;
              animation: in-out 1.25s infinite alternate;
              transform-origin: center center;
            }
          }


        }

        .sub_head01 {
          font-size: 18px;
          color: #0E1428;

          span {
            color: #00a400;
          }
        }


        // =========== SLIDE SECTION =========== //

        .form_slide {
          .slides_frm {
            margin-top: 40px;

            h4 {
              font-weight: 600;
              color: #0E1428;
              margin: 0 0 10px 0;
              font-size: 20px;
            }

            .options01 {
              display: block;
              clear: both;
              width: 100%;
              padding: 10px 0 0 0;
              float: left;

              li {
                width: 49%;
                display: block;
                float: left;
                margin: 0 5px 5px 0;
              }

              .option-check {
                background: transparent;
                border: 1px solid #0E1428;
                border-radius: 7px;
                width: 100%;
                margin-top: 8px;
                cursor: pointer;
                padding: 20px 15px;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
              }

              input:checked+.option-check {
                /* Checked style  */
                background-color: #0E1428 !important;
                color: #fff;
              }

            }

            .btn_next01 {
              padding: 20px 30px;
              color: #FFFFFF;
              font-size: 22px;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              border-radius: 5px;
              cursor: pointer;
              text-align: center;
              font-weight: 600;
              background: #00a400;
              border: 1px solid #00a400;
              width: 50%;
            }

            .field_1 {
              
              padding: 10px 0 0 0;
              

              li {
                width: 49%;
                display: block;
                float: left;
                margin: 0 5px 5px 0;
              }

              label {
                font-weight: 600;
                color: #0E1428;
                margin: 0 0 10px 0;
                font-size: 20px;
                display: block;
                line-height: 140%;
              }

              .secure-badge {
                height: 60px;
                width: 60px;
                float: left;
                background: #F7F7F7;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border: 1px solid #0E1428;
                position: relative;

                span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  font-size: 8px;
                  line-height: 100%;
                  text-align: center;
                  text-transform: uppercase;
                  font-family: 'Inter', sans-serif;
                  font-weight: 400;
                }
              }

              .inputfield_phn {
                float: left;
                height: 60px;
                outline: none;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                -webkit-appearance: none;
                appearance: none;
                padding: 0 20px;
                font-size: 16px;
                font-weight: 400;
                color: #0E1428;
                border: 1px solid #0E1428;
                text-transform: capitalize;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
              }

              .inputfield_n1 {
                width: 100%;
                float: left;
                height: 60px;
                outline: none;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                -webkit-appearance: none;
                appearance: none;
                padding: 0 20px;
                font-size: 16px;
                font-weight: 400;
                color: #0E1428;
                border: 1px solid #0E1428;
                text-transform: capitalize;
              }

              // =========== ERROR MESSAGE  =========== //
              .error_inpt {
                background: #FDEDED;
                padding: 5px 15px;
                line-height: 140%;
                color: #FF0000;
                font-weight: 400;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                font-size: 14px;
                display: block;
                margin: 5px 0 0 0;
                float: left;
                font-weight: 400;
                width: 100%;
              }
            }

          }

          // =========== SLIDE PRE-LOADER  =========== //
          .btn-loading {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: -1px;
            background: #ffffffb9;
            z-index: 150;
            display: none;

            .content {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              max-width: 800px;
              padding: 0 5%;

              .loader_rolling {
                img {
                  max-width: 125px;
                  display: block;

                }
              }
            }

          }
        }

      }

      // =========== STICKY CONTENT SECTION =========== //

      .badge01 {
        width: 100%;
        background: #FFEC51;
        position: -webkit-sticky;
        position: sticky;
        top: 15px;
        float: right;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        display: block;

        &::before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 30px 30px 30px 0;
          border-color: transparent #FFEC51 transparent transparent;
          position: absolute;
          top: 50%;
          left: -26px;
          transform: translateY(-50%);
        }

        .content {
          width: 100%;
          margin: 0 auto;
          padding: 30px;
          text-align: center;

          h5 {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0px;
          }

          .table_1 {
            display: flex;
            justify-content: center;

            tr {
              width: 100%;

              td {
                text-align: center;
                width: 100%;
                font-size: 18px;
                padding-right: 20px;
                font-weight: 800;
                img{
                  width:40px;
                  margin-bottom:10px;
                }
              }
              
            }
          }
        }
      }
    }

    /*================================================
06 - LOADING PAGE SECTION
==================================================*/

    .step-loading {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: -1px;
      background: #F7F7F7;
      z-index: 150;
      display: none;

      .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 800px;
        padding: 0 5%;

        .logo {
          img {
            width: 250px;
            clear: both;
            display: block;
            margin: 0 auto 20px auto;
            float: none;
          }
        }

        .logo-1 {
          img {
            height: 150px;
            clear: both;
            display: block;
            margin: 0 auto 20px auto;
            float: none;
          }
        }

        .resolver-logo {
          img {
            max-width: 125px;
            width: 60%;
            display: block;
            margin: 0 auto 20px auto;
          }
        }

        .loader_rolling {
          img {
            max-width: 125px;
            display: block;
            margin: 0 auto 20px auto;
          }
        }
      }

    }

    /*================================================
07 - THANKYOU PAGE SECTION
==================================================*/
    .thankyou_sec {
      .Thank_conSec {
        margin-top: 30px;
        text-align: center;
        padding: 40px 45px 20px;
        background: #fff;
        box-shadow: 0 8px 24px rgba(149, 157, 165, .2);

        h2 {
          font-size: 34px;
          color: #0E1428;
          font-weight: 800;
          margin-bottom: 35px;
        }

        h4 {
          font-size: 18px;
          line-height: 140%;
          color: #0E1428;
          font-weight: 400;
        }

        .icon_option_num {
          width: 70%;
          margin: 0 auto;
          margin-bottom: 20px;
          font-size: 24px;
          padding: 20px 30px;
          text-align: center;
          background-size: 200% auto;
          color: white;
          border-radius: 10px;
          display: block;
          border: 0px;
          font-weight: 600;
          box-shadow: 0px 0px 14px -7px #45b2e4;
          background-image: linear-gradient(45deg,
              #1899D6 0%,
              #45b2e4 51%,
              #1899D6 100%);

          cursor: pointer;
          user-select: none;
          -webkit-user-select: none;
          touch-action: manipulation;
          animation: 2s cubic-bezier(0, 0, 0.58, 1) infinite rocking;
          transition: 0.2s;

          @keyframes rocking {

            0%,
            25%,
            to {
              transform: rotate(0);
            }

            50% {
              transform: rotate(2deg);
            }

            75% {
              transform: rotate(-2deg);
            }
          }

          span {
            display: inline-block;
            vertical-align: middle;
          }

          .sctop1 {
            display: none;
          }
        }

        .txt_clr {
          font-size: 16px;
          color: #0E1428;
          font-weight: 500;

          span {
            font-size: 16px;
            color: red;
            font-weight: 500;
          }
        }
      }

      .badge01 {
        width: 100%;
        background: #FFEC51;
        position: -webkit-sticky;
        position: sticky;
        top: 15px;
        float: right;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        display: block;

        &::before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          position: absolute;
          transform: translateY(-50%);
          top: auto;
          bottom: -18px;
          left: 30px;
          transform: none;
          border-width: 20px 20px 5px 20px ;
          border-color: #ffeb51 transparent transparent transparent ;
        }

        .content {
          width: 100%;
          margin: 0 auto;
          padding: 30px;
          text-align: center;

          h5 {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0px;
          }

          .table_1 {
            display: flex;
            justify-content: center;

            tr {
              width: 100%;

              td {
                text-align: center;
                width: 100%;
                font-size: 18px;
                padding-right: 20px;
                font-weight: 800;
                img{
                  width:40px;
                  margin-bottom:10px;
                }
              }
              
            }
          }
        }
      }

    }

    /*================================================
06 - FOOTER SECTION
==================================================*/

    footer {
      background: #F7F7F7;
      font-size: 14px;
      margin-top: 170px;

      .link_sec {
        a {
          text-decoration: none;
          font-size: 14px;
          color: #0E1428;
        }
      }
      .link_secc {
        a {
          color: #0D6EFD ;
          text-decoration: none;
          font-size: 14px;
        }
      }

    }

  }

  $break-large: 1367px;
  $break-ipad-pro: 1024px;
  $break-min-ipad: 768px;
  $break-max-ipad: 991px;
  $break-min-tab: 577px;
  $break-max-tab: 767px;
  $break-mob: 576px;

  @media (max-width: 1200px) {
    .banner_section {
      .main_sec {

        .yellow_head {
          span {
            display: block;
          }
        }
      }
    }
  }

  @media (max-width: $break-min-ipad) {

    header {
      .logo_main1 {
        width: 70% !important;
        margin-top: 20px;
      }

      .hdr_imgs {
        .union-flag {
          height: 45px !important;
          padding-right: 10px;
          margin-top: 20px;
        }


      }
    }

    .banner_section {
      .main_sec {
        padding-right: 10px !important;

        .yellow_head {
          font-size: 17px !important;
        }

        h2 {
          font-size: 23px !important;
        }

        .sub_head01 {
          font-size: 17px !important;
        }

      }

      .badge01 {
        margin-top: 30px;

        &::before {
          border-width: 20px 20px 5px 20px !important;
          top: auto !important;
          bottom: -18px !important;
          left: 30px !important;
          transform: none !important;
          border-color: #ffeb51 transparent transparent transparent !important;
        }

      }

      .form_slide {

        .btn-loading {
          .content {
            .loader_rolling {
              img {
                margin: 0 auto;
              }
            }
          }

        }
      }
    }

    .thankyou_sec {
      .Thank_conSec {
        padding: 30px 20px 20px !important;

        .icon_option_num {
          width: 100% !important;
          font-size: 20px !important;
        }
      }
    }
  }


  @media (max-width: $break-mob) {
    .banner_section {
      .main_sec {
        .yellow_head {
          font-size: 15px !important;
        }

        h2 {
          margin-top: 25px !important;
          font-size: 20px !important;
          line-height: 125% !important;
        }

        .sub_head01 {
          font-size: 15px !important;
          padding-top: 12px !important;
          padding-bottom: 10px !important;
        }

        .form_slide {
          .slides_frm {
            margin-top: 20px !important;

            .options01 {
              li {
                width: 100% !important;
              }
            }
          }

          .field_frm {
            margin-top: 0px !important;
          }

          .field_1 {
            li {
              width: 100% !important;
            }

            .mt3_1 {
              margin-top: 15px !important;
            }

            label {
              font-size: 16px !important;
            }
          }

          .btn_next01 {
            width: 100% !important;
          }

          .btn-loading {
            .content {
              .loader_rolling {
                img {
                  margin: 0 auto;
                }
              }
            }

          }
        }
      }

    }

    .thankyou_sec {
      .Thank_conSec {
        padding: 30px 20px 20px !important;

        .icon_option_num {
          width: 100% !important;
          font-size: 16px !important;
          padding: 20px 20px !important;
        }
      }
    }

    footer {
      margin-top: 180px !important;

    }
  }
  .user_cntnt{
    font-size: 12px;
    word-spacing: 3px;
    line-height: 14px;
  }

}