/*
*******************************************
Template Name: Then.tax Website
Author: Ramees
Date:10-08-2023
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - Questionnaie 

08 - FOOTER 
09 - MODAL 
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/
.SPR_SPH_V7_1{

    .d-block{
        display: block ;
    }
    .d-none{
        display: none ;
    }
$background_color_1:#ffffff;
$background_color_2:#0b334a;
$background_color_3:#ffc248;
$background_color_4:#052233;
$background_color_5:#f0b944;
$background_color_6:#f9fbfc;

$color_1:#ffc248;
$color_2:#0b334a;
$color_3: #ffff;
$color_4:#eea81e;
$color_5:#979797;
$font-normal:normal;


$background_gradient_1:linear-gradient(264deg,#f69b00,#ed6a00);
$background_gradient_01:linear-gradient(92deg,#f69b00,#ed6a00);

// ======border===========
$box-shadow1: 0 0 0 0.2rem #ffc2488c;

/*================================================
02 - Sass Mixins
==================================================*/

/*================================================
	BODY STYLES
==================================================*/
*, ::after, ::before {
    box-sizing: border-box;
}

*{
	outline: none;
}

html {
	scroll-behavior: smooth;
}
a{
	cursor: pointer;
	text-decoration: none !important;
	color: unset !important;
}

em {
    font-style: normal;
}


body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
}

.animate-shake {
    animation: shake 1.5s ease-in-out 1;
    animation-iteration-count: infinite;

}

.p_relative{ 
	position: relative;
}

.p_absolute{ 
	position: absolute;
	top: 10px;
	left: 0px;
}

.animate-ping{
	animation: ping 1s cubic-bezier(0,0,.2,1) infinite;
	width: 24px;
    height: 24px;
    background: #395e90;
    border-radius: 50%;

}

.Seguro__Latinos{
	font-family: 'Poppins', sans-serif !important;
	.main_flic{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.inner_content{
			padding-top: 26px;
			@media only screen and (max-width: 480px){
				padding-top: 22px;
			}
			h2{
				font-size: 36px;
				font-weight: 500;
				@media only screen and (min-width: 481px) and (max-width: 768px){
					font-size: 32px!important;
				}
				@media only screen and (min-width: 0px) and (max-width: 480px){
					font-size: 22px!important;
				}
			}//h2
		}//inner_content
		.inner_bg {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 20px 0;
			@media only screen and (min-width: 481px) and (max-width: 768px){
				flex-direction: column;
			}
			@media only screen and (max-width: 480px){
				padding: 16px 0;
				flex-direction: column;
			}
			.bg_img1,.bg_img2{
				width: 100%;
			}
			p{
				font-size: 14px;
			}

			/*** VARIABLES ***/
			$black: #1d1f20;
			$blue: #83e4e2;
			$green: #a2ed56;
			$yellow: #fddc32;
			$white: #fafafa;

			/*** EXTEND ***/
			/* box-shadow */
			%boxshadow {
				box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05), 0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
			}

			ol.gradient-list {
				// counter-reset: gradient-counter;
				list-style: none;
				margin: 1.75rem 0;
				padding-left: 1rem;
			@media only screen and (max-width: 480px){
				margin: 1.8rem 0;
			}
			> li {
				@extend %boxshadow;
				background: white;
				// border-radius: 0 0.5rem 0.5rem 0.5rem;
				// counter-increment: gradient-counter;
				padding:7px 30px 7px 35px;
				position: relative;
				font-size: 15px;
				background:url('../../img/li.png') no-repeat left center;
				border-bottom: dashed 1px #a39e9e;
				@media only screen and (max-width: 480px){
					font-size: 15px;
					padding: 7px 30px 7px 35px;
				}
				// &::before,
				// &::after {
				// background: linear-gradient(135deg, #7b7eff 0%, #3489d3 100%);
				// border-radius: 1rem 1rem 0 1rem;
				// content: '';
				// height: 32px;
				// left: -12px;
				// overflow: hidden;
				// position: absolute;
				// top: -3px;
				// font-size: 15px;
				// width: 32px;
				// }
				// &::before {
				// align-items: center;
				// @extend %boxshadow;
				// content: "\1F973"; // Unicode code point for 🥳
				// color: $black;
				// display: flex;
				// justify-content: center;
				// padding: 0.125em 0.25em;
				// z-index: 1;
				// }

				// $emojis: "\1F973" "\1F389" "\1F38A" "\1F973"; // Create a list of emojis

				// &:nth-child(1):before { content: nth($emojis, 1); }
				// &:nth-child(2):before { content: nth($emojis, 2); }
				// &:nth-child(3):before { content: nth($emojis, 3); }
				// &:nth-child(4):before { content: nth($emojis, 4); }
				// &:nth-child(5):before { content: nth($emojis, 1); }
				// &:nth-child(6):before { content: nth($emojis, 2); }
				// &:nth-child(7):before { content: nth($emojis, 3); }
				// &:nth-child(8):before { content: nth($emojis, 4); }

				+ li {
				margin-top: 0px;
				}
			}
			}

		}//inner_bg
		.animated-arrow {
			width: 50%;
			position: relative;
			z-index: 1;
			top: 0;
			left: 0;
			margin-top: -50px;
			text-align: center;
			float: right;
			-webkit-animation: mover 1s infinite alternate;
			animation: mover 1s infinite alternate;
			.animated-arrow-img {
				-webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
				-moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
				-ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
				animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
				box-shadow: 0 0 0 0 #107eb4;
				border-radius: 100%;
				width:45px;
			}
		}

		@-webkit-keyframes pulse {
			to {
				box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}
		
		@-moz-keyframes pulse {
			to {
				box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}
		
		@-ms-keyframes pulse {
			to {
				box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}
		
		@keyframes pulse {
			to {
				box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
			}
		}
		@-webkit-keyframes mover {
			0% {
				transform: translate(-50%, 0);
			}
			100% {
				transform: translate(-50%, -20px);
			}
		}
		
		@keyframes mover {
			0% {
				transform: translate(-50%, 0);
			}
			100% {
				transform: translate(-50%, -20px);
			}
		}
		
		.form_slides{
			display: flex;
			justify-content: center;
			align-items: center;
			.animate {
				-webkit-animation-duration: 0.7s;
				animation-duration: 0.7s;
				-webkit-animation-fill-mode: both;
				animation-fill-mode: both;
			}
			
			/*=== Optional Delays, change values here  ===*/
			.five {
				-webkit-animation-delay: 0.3s;
				-moz-animation-delay: 0.3s;
				animation-delay: 0.3s;
			}


			/*=== FADE IN  ===*/
			@keyframes fadeIn {
				from {
				opacity: 0;
				}
			
				to {
				opacity: 1;
				}
			}

			@keyframes ping {
				0% {
					transform: scale(1);
					opacity: 1;
				}
				
				75%, 100% {
					transform: scale(2);
					opacity: 0;
				}
			}

			@keyframes shake {
				0%, 8%, 16%, 24%, 32%, 40%, 48%, 56%, 64% {
					transform: rotate(-10deg);
				}
				4%, 12%, 20%, 28%, 36%, 44%, 52%, 60% {
					transform: rotate(10deg);
				}
				65%, 100% {
					transform: rotate(0deg);
				}
			}
			  


			.fadeIn {
				-webkit-animation-name: fadeIn;
				animation-name: fadeIn;
			}

			.slide_1st,.slide_2st,.slide_thk{
				padding: 0 0 30px;
				z-index: 999;
				.form_sec{
					padding: 0 15px;
					font-family: 'Poppins', sans-serif !important;
					h2 {
						font-size: 1.5em;
						font-weight: 700;
						color: #000a39;
						line-height: 1.2em;
						text-align: center;
						margin-bottom: 20px;
						@media only screen and (max-width: 480px){
							font-size: 1.4em;
						}
					}//h2
					h2.retroshadow {
						color: #1f1f1f;
						letter-spacing: .05em;
						font-size: 24px;
						// text-shadow: 4px 4px 0px #d5d5d5, 7px 7px 0px rgba(0, 0, 0, 0.2);
					}
					.icon_option {
						width: 100%;
						font-size: 24px;
						text-align: center;
						transition: .5s;
						background: #2c88d9;
						background-size: 200% auto;
						color: #fff;
						display: block;
						border: 0;
						font-weight: 600;
						border-radius: 3px;
						padding: 15px 0;
						margin-top: 10px;
						box-shadow: 0 0 14px -7px #f09819;
						cursor: pointer;
						user-select: none;
						-webkit-user-select: none;
						touch-action: manipulation;
						@media only screen and (max-width: 480px){
							font-size: 18px;
						}
						.sctop1 {
							display: none;
						}
						span {
							display: inline-block;
							vertical-align: middle;
							font-weight: 700;
						}
					}//icon_option

				}//form_sec
			}//slide_1st

			.boxme {
				padding: 30px 34px;
				// background-color: #fff;
				// border-top: 5px solid #00007c;
				font-size: 14px;
				line-height: 1.75;
				font-weight: 400;
				// box-shadow: 0 0 7px 0 rgba(0,0,0,.2);
				transition: .2s;
				.tnk_head{
					font-size: 34px;
				}
				.attribute {
					font-size: 18px;
					color: #04ade5;
				}
				p{
					font-size: 16px;
				}
				.t2c_btn {
					background-color: #2c88d9;
					border-radius: 3px;
					padding: 15px 0;
					margin-top: 10px;
					text-decoration: none;
					font-weight: 700;
					color: #ffffff !important;
					font-size: 1.5rem;
					display: block;
				}//t2c_btn
			}//boxme
		}//form_slides

		div{
			.icon_option_num {
				width: 50%;
				margin: 0 auto;
				margin-bottom: 20px;
				font-size: 24px;
				padding: 20px 30px;
				text-align: center;
				background-size: 200% auto;
				color: white;
				border-radius: 10px;
				display: block;
				text-decoration: none !important;
				border: 0px;
				font-weight: 600;
				box-shadow: 0px 0px 14px -7px #f09819;
				background-image: linear-gradient(45deg,
					#5da5ff 0%,
					#005ce5 51%,
					#006eff 100%);
				cursor: pointer;
				user-select: none;
				-webkit-user-select: none;
				touch-action: manipulation;
				animation: 2s cubic-bezier(0, 0, 0.58, 1) infinite rocking;
				transition: 0.2s;
		
				@keyframes rocking {
					0%,
					25%,
					to {
					transform: rotate(0);
					}
		
					50% {
					transform: rotate(2deg);
					}
		
					75% {
					transform: rotate(-2deg);
					}
				}
				span {
					display: inline-block;
					vertical-align: middle;
				}
				.sctop1 {
					display: none;
				}
			}
		
			.icon_option_num_a {
				width: 60%;
				letter-spacing: 4px;
				font-size: 25px;
				padding: 17px 7px;
				img {
					width: 26px;
					height: 24px;
				}
			}
		}//callbtn animation for SPR_SPH_V7_1


		.form-bg{
			margin-top: 30px;
			padding: 30px 0;
			min-height: 300px;
			background-color: #fff;
			background: url('../../img//bg-f.png') repeat left top;
			border-top: 5px #f2f2f2 solid;
			border-bottom: 5px #f2f2f2 solid;
		}
		footer {
			background-color: #fff;
			color: #000;
			text-align: center;
			position: relative;
			width: 100%;
			bottom: 0;
			margin-top: 40px;
			padding-top: 20%!important;
			p {
				font-size: 14px;
			}
			.foot {
				color: #00f!important;
				text-decoration: underline;
			}
		}
	}//main_flic
}
}//Seguro__Latinos


//////////// FOOTER ///////////////
/////////// FOOTER STYLE ////////



/*================================================
	MEDIA QUERY
==================================================*/




@media only screen and (max-width: 600px) {
	.ACA_SPH_V7 .Seguro__Latinos { 
		.main_flic div { 
			.icon_option_num_a {
				width: 100%;
				border-radius: 4px !important;
				font-size: 17px !important;
			}
		}
	}
	.p_absolute{ 
		top: 3px !important;
	}
  }