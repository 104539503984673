
@import 'custom';

a {
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
}
 header {
  background:  #eee;
 }


 .hide{
  display: none;
 }

 .show{
  display: block;
 }



 $color1 : #3b82f6;
 $color2 : white;
 $color_3: #111;
 $background-color_1: #fee6e3;
 $background-color_2: #111;
 $background-color_3: #ffdeda;
 $background-color_4:  #106afd;

.SPR_VD_V9{

 .wrap-content {
  max-width: 36rem;
  margin: 0 auto;

  header {
    background:  $color1;
    text-align: center;
    padding: 10px 0;
    color: $color2 ;
    h1 {
      font-weight: bolder;
      font-size: 23px;
      color: $color2 ;
    }
    p {
      margin: 0 auto;
      max-width: 33rem;
      font-size: 16px;

    }
  }
  .textc2 {
    font-weight: bold;
    padding: 5px 0;
  }
  .video-post {
    position: relative;
     .volume {
      position: absolute;
      left: auto;
      top: 18px;
      right: 18px;
      background:  $color2;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      font-size: 22px;
      text-align: center;
      z-index: 99;
      cursor: pointer;
      border: 3px solid  $color2;
      line-height: 32px;
      transition: all ease-in 0.5s;
      &:hover {
        border-color:   $color2;
        background:  $background-color_4;
         color: $color2;
         transition: all ease-in 0.5s;
      }
     }
    .play-pause{
      position: absolute;
      left: auto;
      top: 68px;
      // top: 18px;
      right: 18px;
      background:  $color2;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      font-size: 22px;
      text-align: center;
      z-index: 99;
      cursor: pointer;
      border: 3px solid  $color2;
      line-height: 32px;
      transition: all ease-in 0.5s;
      &:hover {
        border-color:   $color2;
        background:  $background-color_4;
        color: $color2;
        transition: all ease-in 0.5s;
      }
    }
    video {
      cursor: pointer;
      width: 100%;
      box-sizing: border-box;
      height: 550px;
      object-fit: cover;
      border: 3px solid $color1;
      padding: 0;
      margin: 0;
      //object-position: bottom;
      object-position: 25% 90%;
    }
  }

  .bottm-video-content {
    // .call-btn {
    //   background: $color1;
    //   font-size: 2rem;
    //   padding: 15px 30px;
    //   text-transform: uppercase;
    //   font-weight: bolder;
    //   width: 400px;
    //   border-radius: 60px;
    //   border: none;
    //   color: $color2;
    // }
    border: 2px solid #eee;
    padding-bottom: 30px;



    .call-btn {
      align-items: center;
      background-color: $color1;
      border: 2px solid #111;
      border-radius: 60px;
      box-sizing: border-box;
      color:  $color2;
      cursor: pointer;
      display: inline-flex;
      font-size: 32px;
      height: 85px;
      justify-content: center;
      line-height: 24px;
      max-width: 100%;
      padding: 30px;
      position: relative;
      text-align: center;
      width: 350px;
      font-weight: bold;
      text-decoration: none;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;

      &:after {
        background-color: $background-color_2;
        border-radius: 60px;
        content: "";
        display: block;
        height: 85px;
        left: 0;
        width: 100%;
        position: absolute;
        top: -2px;
        transform: translate(8px, 8px);
        transition: transform .2s ease-out;
        z-index: -1;
      }
      &:hover {
         background-color: #4e97db;
        &:after {
          transform: translate(0, 0);

        }
        outline: 0;
      }
      &:active {
        background-color: $background-color_4;
        outline: 0;
      }
    }

    .qs-slide {
      .qs-text {
        font-size: 20px
      }
      .qs-status {
        display: flex;
        gap: 10px;
        max-width: 250px;
        margin: 0 auto;
        margin-bottom: 25px;


        li {
          width: 100%;
          list-style-type: none;

          span{
            background:  #eee;
            height: 8px;
            border-radius: 10px;
            width: 100%;
            display: block;
            &.active {
              background: $background-color_4;
            }
          }
        }
      }
      .qs-loader {
        img {
          height: 50px;
        }
      }
    }


    .btn-comm {
      @extend .call-btn ;
      width:100%;
      padding: 0 15px;
      font-size: 22px;
      height: 50px;
      font-weight: 500;
      &::after {
        height: 48px;
      }
    }
  }
 }






// .text-message-item {
//   display: none !important;
// }

.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}

@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(30px);
              transform: translateY(30px);
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(30px);
              transform: translateY(30px);
    }
    100% {
      -webkit-transform: translateY(0px);
              transform: translateY(0px);
    }
  }
.body-inner {
  height: 100vh;
}

footer {
  float: left;
  width: 100%;
  padding-top: 50px;
  p {
    font-size: 14px;
  }
  a {
    color: $colorBlack;
    text-decoration: none;
  }
}
.text1 {
   font-family: Georgia, 'Times New Roman', Times, serif;
   font-size: 18px;
   font-weight: bold;
   color: #4b5563;
}
.text2 {
  font-size: 32px;
  color: #4b5563;
}
.support-online {
   span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
    display: inline-block;
    position: relative;
    top: 3px;
     background: #22c55e;
  }


  .pulse {
    animation: pulse-animation 1.5s infinite;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(162, 221, 122, 0.616);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }


}
@media screen and (max-width: 590px) {

}
@media only screen and (min-width: 992px) {
 .wrap-content{
  .video-post video{
    height:500px;
    object-position: 25% 93%;
}
 }

  }

  @media screen and (max-width: 490px) {

    .wrap-content {
      header {
        h1 {
          font-size: 17px;
        }
        p {
          font-size: 13px;
          padding: 0 10px;
        }

      }
      .video-post {
        video {
          height: 430px;
          object-position: bottom;
        }
      }
      .textc2 {
        font-size: 12px;
      }
      .bottm-video-content {
        padding:  0 15px;
        border: none;
        .qs-slide{
          .qs-loader {
            img {
              height: 60px;
            }
          }

          .qs-status{
            li {
              span {
                height: 4px;
              }
            }
          }
        }



        .call-btn {
          width: 250px;
          font-size: 25px;
          height: 45px;
          &::after {
            height: 64px;
          }

        }
        .btn-comm {
           font-size: 18px;

          &::after {
            height: 45px !important;
          }
        }

      }




    }
    footer {
      p {
        font-size: 12px;
      }
    }



  }


}
