/*
*******************************************
Template Name: Seguro Para Latinos
Author: Anush
Date: 19-09-2023
Template By: Vandalay Designs
Copyright 2020-2024 Vandalay Business Solution

* This file contains the styling for Free Debt Assessment, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************
*/

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

a{
    text-decoration: none;
}

body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.__SEGUROhome{
    font-family: 'Poppins', sans-serif;
    header{
        background: linear-gradient(252deg, #ffffff, #ffffff -4%, #ffffff);
        padding: 10px 0;
        width: 100%;
        .top_notch{
            padding: 0 20px;
            .nav_barACA{
                display: flex;
                align-items: center;
                .img_slot{
                    img {
                        width: 65px;
                    }//img
                }//img_slot
                .logo_text{
                    p{
                        margin: 0;
                        font-weight: 600;
                        color: #111111;
                        font-size: 17px;
                        padding-left: 15px;
                        @media screen and (max-width: 510px){
                            font-size: 13px 
                            !important;
                        }
                    }
                }
            }//nav_barACA
            .nav_btnACA{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .btn_clickcall{
                    a{
                        text-decoration: underline;
                        color: #3b82f6;
                        font-size: 22px;
                        font-weight: 600;
                        @media screen and (max-width: 480px){
                            font-size: 18px;
                        }
                    }
                }//btn_clickcall
            }//nav_btnACA
        }//top_notch
    }//header        

    .cs_land_paGe{
        .bg_Br_vw{
            background: url(../../img/SPR_WA_V11/bg2.jpg);
            width: 100%;
            min-height: 670px;
            background-position: center;
            background-size: cover;
            position: relative;
            &::before{
                content: "";
                min-height: 100%;
                width: 100%;
                background: #000000c4;
                position: absolute;
            }
            .tke_txT_Dwn{
                color: #fff;
                min-height: 670px;
                justify-content: center;
                align-items: center;
                align-content: center;
                z-index: 5;
                margin: 0;
                position: relative; 
                .txt_notice{
                    padding: 0 20px;
                    .content{
                        text-align: center;
                        padding: 20px 0;
                        .lts_put_HeD{
                            font-size: 36px;
                            font-weight: 500;
                            line-height: 140%;
                            padding-bottom: 25px;
                            margin: 0;
                            @media screen and (max-width: 980px){
                                font-size: 32px;
                            }
                            @media screen and (max-width: 780px){
                                font-size: 28px;
                            }
                            @media screen and (max-width: 510px){
                                font-size: 24px;
                                padding-bottom: 15px;
                            }
                        }//lts_put_HeD
                        .sub_bttm {
                            font-size: 20px;
                            font-weight: 400;
                            color: #c5c5c5;
                            line-height: 120%;
                            margin: 0;
                            padding-bottom: 25px;
                            @media screen and (max-width: 780px){
                                font-size: 18px;
                            }
                            @media screen and (max-width: 510px){
                                font-size: 17px;
                                padding-bottom: 15px;
                            }
                        }//sub_bttm
                        .dont_frgt_BTn{
                          padding: 30px 0;
                            .btn_trnOn {
                                display: inline-block;
                                border: 0;
                                outline: 0;
                                padding: 17px 55px;
                                line-height: 1.4;
                                background: linear-gradient(#3b82f6, #3b82f6);
                                border-radius: 50px;
                                color: white !important;
                                font-size: 19px;
                                font-weight: 700;
                                cursor: pointer;
                                position: relative;
                                @media screen and (max-width: 510px){
                                    font-size: 16px;
                                    padding: 17px 30px;
                                }
                            }//btn_trnOn
                            //////////////////////////////
                            .efct_filed {
                                animation: blinkBG 1.5s infinite;
                            }
                            
                            @keyframes blinkBG {
                                0% {
                                box-shadow: 0 0 0 0 rgb(63, 114, 255);
                                }
                            
                                70% {
                                box-shadow: 0 0 0 30px rgba(66, 94, 255, 0);
                                }
                            
                                100% {
                                box-shadow: 0 0 0 0 rgba(66, 85, 255, 0);
                                }
                            }
                        }//dont_frgt_BTn
                    }//content
                    .nite_kTm_hv{ 
                        text-align: center;
                        padding-top: 10px;
                        img{
                            width: 190px;
                        }
                    }//nite_kTm_hv
                }//txt_notice
                .hr_tips_menu{
                    display: grid;
                    grid-template-columns: 1fr max-content 1fr;
                    grid-column-gap: 1.2rem;
                    align-items: center;
                    padding-top: 40px;
                    &:before,&:after {
                        content: "";
                        display: block;
                        height: 1px;
                        background-color: currentColor;
                    }   
                }//hr_tips_menu
                .pnts_By_PntS{
                    display: flex;
                    list-style: none;
                    padding: 0;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 10px;
                    margin: 0;
                    @media screen and (max-width: 510px){
                      display: grid;
                      grid-template-columns: repeat(2, 1fr);
                  }
                    .pin_Here{
                        display: flex;
                        align-items: center;
                        img{
                            height: max-content;
                        }
                        p{
                            margin: 0;
                            font-size: 13px;
                            padding-left: 10px;
                        }
                    }//pin_Here
                }//pnts_By_PntS
            }//tke_txT_Dwn
        }//bg_Br_vw
    }//cs_land_paGe


    /*--------------------------------------------------------------
10. accordion
----------------------------------------------------------------*/
.acco_desin{
  margin: 40px 0;
  a{
    text-decoration: none !important;
  }
    .accordion {
      width: 100%;
      .accordion-list {
        padding: 0;
        .accordion-item {
          list-style: none;
          position: relative;
          height: max-content;
          border: 0;
          input[type="checkbox"] {
            display: none;
          }
          label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            background: #001b49;
            cursor: pointer;
            position: relative;
            z-index: 2;
            border-radius: 30px;
            .opened {
              display: none;
            }
            .closed {
              display: block;
            }
            p{
              color: #fff;
              font-weight: 600;
              margin: 0;
            }
          }
  
          .content {
            width: 100%;
            transition: all 0.3s ease;
            opacity: 0;
            pointer-events: none;
            padding: 8px 16px;
            position: relative;
            z-index: 1;
            padding-left: 20px;
            max-height: 0;
            margin-top: -38px;
            color: #717171;
            padding: 25px 25px 25px 25px;
            border: 1px solid #eee;
            font-size: 1rem;
            &.show {
              margin: 13px 0;
              max-height: fit-content;
              opacity: 1;
              pointer-events: all;
            }
            p {
              margin-bottom: 8px;
              color: gray;
            }
          }
  
          input[type="checkbox"]:checked ~ label .opened {
            display: block;
          }
          input[type="checkbox"]:checked ~ label .closed {
            display: none;
          }
        }
      }
    }
}
/*--------------------------------------------------------------
11. Hero
----------------------------------------------------------------*/


    footer.ptX_ct3{
        padding-top: 140px 0 20px;
        box-shadow: -5px -12px 34px -4px rgba(0,0,0,0.1);
        p{
            font-size: 12px;
            .foot{
                text-decoration: underline;
                color: blue;
            }
            a{
                text-decoration: none;
                color: unset;
            }
        }
    }//footer


    @media screen and (min-width: 0px) and (max-width: 480px){
        .btn_clickcall{
            button {
                padding: 6px 20px !important;
            }
        } 
        .img_slot {
            img {
                width: 55px !important;
            }
        }
    }

}//__SEGUROhome

.loader_full_Pg{
    display:flex;
    justify-content:center;
    align-items:center;
    height:85vh;
    #wifi-loader {
        --background: #627fff;
        --front-color: #292cf0;
        --back-color: #c3c6de;
        --text-color: #414556;
        width: 64px;
        height: 64px;
        border-radius: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      #wifi-loader svg {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      #wifi-loader svg circle {
        position: absolute;
        fill: none;
        stroke-width: 6px;
        stroke-linecap: round;
        stroke-linejoin: round;
        transform: rotate(-100deg);
        transform-origin: center;
      }
      
      #wifi-loader svg circle.back {
        stroke: var(--back-color);
      }
      
      #wifi-loader svg circle.front {
        stroke: var(--front-color);
      }
      
      #wifi-loader svg.circle-outer {
        height: 86px;
        width: 86px;
      }
      
      #wifi-loader svg.circle-outer circle {
        stroke-dasharray: 62.75 188.25;
      }
      
      #wifi-loader svg.circle-outer circle.back {
        animation: circle-outer135 1.8s ease infinite 0.3s;
      }
      
      #wifi-loader svg.circle-outer circle.front {
        animation: circle-outer135 1.8s ease infinite 0.15s;
      }
      
      #wifi-loader svg.circle-middle {
        height: 60px;
        width: 60px;
      }
      
      #wifi-loader svg.circle-middle circle {
        stroke-dasharray: 42.5 127.5;
      }
      
      #wifi-loader svg.circle-middle circle.back {
        animation: circle-middle6123 1.8s ease infinite 0.25s;
      }
      
      #wifi-loader svg.circle-middle circle.front {
        animation: circle-middle6123 1.8s ease infinite 0.1s;
      }
      
      #wifi-loader svg.circle-inner {
        height: 34px;
        width: 34px;
      }
      
      #wifi-loader svg.circle-inner circle {
        stroke-dasharray: 22 66;
      }
      
      #wifi-loader svg.circle-inner circle.back {
        animation: circle-inner162 1.8s ease infinite 0.2s;
      }
      
      #wifi-loader svg.circle-inner circle.front {
        animation: circle-inner162 1.8s ease infinite 0.05s;
      }
      
      #wifi-loader .text,.text_2 {
        position: fixed;
        display: flex;
        top: 60%;
        justify-content: center;
        align-items: center;
        text-transform: lowercase;
        font-weight: 500;
        font-size: 21px;
        letter-spacing: 0.2px;
        @media screen and (max-width: 510px){
            font-size: 13px;
            top: 55%;
        }
      }
      
      #wifi-loader .text::before,.text_2::before, #wifi-loader .text::after,.text_2::after{
        content: attr(data-text);
      }
      
      #wifi-loader .text::before,.text_2::before {
        color: var(--text-color);
      }
      
      #wifi-loader .text::after,.text_2::after {
        color: var(--front-color);
        animation: text-animation76 3.6s ease infinite;
        position: absolute;
        left: 0;
      }
      
      @keyframes circle-outer135 {
        0% {
          stroke-dashoffset: 25;
        }
      
        25% {
          stroke-dashoffset: 0;
        }
      
        65% {
          stroke-dashoffset: 301;
        }
      
        80% {
          stroke-dashoffset: 276;
        }
      
        100% {
          stroke-dashoffset: 276;
        }
      }
      
      @keyframes circle-middle6123 {
        0% {
          stroke-dashoffset: 17;
        }
      
        25% {
          stroke-dashoffset: 0;
        }
      
        65% {
          stroke-dashoffset: 204;
        }
      
        80% {
          stroke-dashoffset: 187;
        }
      
        100% {
          stroke-dashoffset: 187;
        }
      }
      
      @keyframes circle-inner162 {
        0% {
          stroke-dashoffset: 9;
        }
      
        25% {
          stroke-dashoffset: 0;
        }
      
        65% {
          stroke-dashoffset: 106;
        }
      
        80% {
          stroke-dashoffset: 97;
        }
      
        100% {
          stroke-dashoffset: 97;
        }
      }
      
      @keyframes text-animation76 {
        0% {
          clip-path: inset(0 100% 0 0);
        }
      
        50% {
          clip-path: inset(0);
        }
      
        100% {
          clip-path: inset(0 0 0 100%);
        }
      }
}//loader_full_Pg




