/*
*******************************************
Template Name: ACA Website
Author: Ramees
Date:10-08-2023
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - Questionnaie 

08 - FOOTER 
09 - MODAL 
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/

.SPR_V2{
$background_color_1:#ffffff;
$background_color_2:#0b334a;
$background_color_3:#ffc248;
$background_color_4:#052233;
$background_color_5:#f0b944;
$background_color_6:#f9fbfc;
$background_color_7:#efefef;
$background_color_8:#f2f4f5;
$background_color_9:#cfcfcf;

$color_1:#ffc248;
$color_2:#1f1f1f;
$color_3: #ffff;
$color_4:#eea81e;
$color_5:#979797;
$font-family:'Poppins', sans-serif !important;


// ======border===========

/*================================================
02 - Sass Mixins
==================================================*/

@mixin shadow-1 {
	-webkit-box-shadow: 0px 0px 23px -6px rgba(207,207,207,1);
-moz-box-shadow: 0px 0px 23px -6px rgba(207,207,207,1);
box-shadow: 0px 0px 23px -6px rgba(207,207,207,1);
}
/*================================================
	BODY STYLES
==================================================*/

*, ::after, ::before {
    box-sizing: border-box;
}


*{
	outline: none;
}

html {
	scroll-behavior: smooth;
}

a{
	cursor: pointer;
	text-decoration: none ;
	color: unset ;
}

em {
    font-style: normal;
}


body {
	margin: 0;
	padding: 0;
	font-family: "Poppins",sans-serif  !important;
	color: $color_2 !important;
	min-height: 100vh;
}

header{
	.logo_place{
		padding: 15px 0;
		width: 100%;
		text-align: center;
	}
}//HEADER SEC
// input:checked+label {
// 	color: $color_1;
// 	background-color: $background_color_3 !important;
// 	border-color: $color_1;
// 	border-radius: 5px;
	
// }
// .radiobtn {
// 	position: absolute;
// 	opacity: 0;
//   }
//   input:checked+div {	
							
// 	color: $color_1;
// 	background-color: $background_color_3 !important;
// 	border-color: $color_1;
// 	border-radius: 5px;
	
// }

.Spanish_ACAques{
	min-height: 100vh;
	margin-bottom: 40px;
	.elementory_sec{
		max-width: 850px;
		.wrapUp_sec{
			padding-top: 30px;
			.QuesFill_sec{
				.OXen_blxi{
					.main_contSec{
						h1{
							font-family: "Poppins",sans-serif ;
							color: #000000;
							font-size: 25px;
							font-weight: 700;
							line-height: 1.4em;
							margin-bottom: 20px;
						}// h1
						div{
							img {
								width: 85%;
								display: inline-block;
							}
						}// DIV 
						.txt_block{
							padding: 20px 0;
							font-size: 1rem;
							line-height: 1.5rem;
							font-family: "Poppins",sans-serif;
						}//txt_block
						.form_sec {
							background-color: #f2f2f2;
							font-family: "Poppins",sans-serif;
							padding: 30px;
							h2 {
								font-size: 33px;
								font-weight: 700;
								line-height: 1.2em;
								text-align: center;
								margin-top: 10px;
								margin-bottom: 20px;
								text-shadow: 2px 2px 5px #999999;
							}// H2
							h5 {
								color: #999999;
								font-size: 21px;
								text-align: center;
								margin-bottom: 40px;
								font-weight: 500;
							}// H5
							.icon_option {
								width: 100%;
								margin-bottom: 20px;
								font-size: 24px;
								padding: 15px 20px;
								text-align: center;
								transition: 0.5s;
								background-size: 200% auto;
								color: white;
								border-radius: 10px;
								display: block;
								border: 0px;
								font-weight: 600;
								box-shadow: 0px 0px 14px -7px #f09819;
								background: rgb(129,185,255);
 background: linear-gradient(90deg, rgba(129,185,255,1) 0%, rgba(0,92,229,1) 51%, rgba(0,110,255,1) 100%);
								cursor: pointer;
								user-select: none;
								-webkit-user-select: none;
								touch-action: manipulation;
								&:hover {
								box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
									rgba(0, 0, 0, 0.23) 0px 6px 6px;
								transform: translateY(-1px);
								}

							
								// .question_{
								// 	background-image: rgb(160, 42, 97);	
								// }
								span {
								display: inline-block;
								vertical-align: middle;
								}

								
								
							}
							.demo_12{ 
								display: none !important;
								&:checked+ label{ 
									background: rgb(7,52,120);
                             background: linear-gradient(90deg, rgba(7,52,120,1) 51%, rgba(9,42,84,1) )
								}
							}
							


							//
							
							
							// 

							.back_btn {
								margin: 15px 0 0;
								padding: 0;
								color: #535353!important;
								font-weight: 400;
								font-size: 16px;
								background: transparent;
								width: 100%;
								border: none;
							}//back_btn
						}//form_sec
						.fade{
							background: #000000ad !important;
						}
						.modal-body{
							font-size: 20px;
							padding: 35px 10px;
							font-weight: 600;
							

						}

						
					}//main_contSec
				}//OXen_blxi
			}//QuesFill_sec
		}//wrapUp_sec
	}//elementory_sec
}//Spanish_ACAques





/*================================================
06 - THANKYOU PAGE DESIGN SECTION
==================================================*/
.Thank_ACAques{
	// min-height: 100vh;
	margin-bottom: 100px;
	.ContThax_sec{
		max-width: 850px;
		.WeKXr_Bxt{
			padding-top: 30px;
			.Thank_conSec{
				text-align: center;
				padding: 38px 20px 20px;
				background: #ffffff;
				border-top: 5px solid #00af2f;
				box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
				h1{
					color: #00af2f;
					font-size: 30px;
					font-weight: 600;
					line-height: 1.4em;
					margin-bottom: 20px;
				}// h1
				h3{
					font-size: 18px;
					font-weight: 500;
					line-height: 120%;
					color: #1f1f1f;
					margin-bottom: 20px;
				}
				h4{
					font-size: 18px;
					font-weight: 700;
					line-height: 120%;
					color: #1f1f1f;
					margin-bottom: 20px;
				}
				h2{
					font-size: 30px;
					font-weight: 700;
					line-height: 120%;
					color: #383838;
					margin-bottom: 28px;
				}
				div{
					.icon_option_num {
						width: 50%;
						margin: 0 auto;
						margin-bottom: 20px;
						font-size: 24px;
						padding: 20px 30px;
						text-align: center;
						background-size: 200% auto;
						color: white;
						border-radius: 10px;
						display: block;
						text-decoration: none !important;
						border: 0px;
						font-weight: 600;
						box-shadow: 0px 0px 14px -7px #f09819;
						background-image: linear-gradient(45deg,
							#5da5ff 0%,
							#005ce5 51%,
							#006eff 100%);
						cursor: pointer;
						user-select: none;
						-webkit-user-select: none;
						touch-action: manipulation;
						animation: 2s cubic-bezier(0, 0, 0.58, 1) infinite rocking;
						transition: 0.2s;
				
						@keyframes rocking {
							0%,
							25%,
							to {
							transform: rotate(0);
							}
				
							50% {
							transform: rotate(2deg);
							}
				
							75% {
							transform: rotate(-2deg);
							}
						}
						span {
							display: inline-block;
							vertical-align: middle;
						}
						.sctop1 {
							display: none;
						}
					}
				
					.icon_option_num_a {
						width: 60%;
						letter-spacing: 4px;
						font-size: 25px;
						padding: 17px 7px;
						img {
							width: 26px;
						}
					}
				}
			}
		}
	}

/*================================================
		MEDIA QUERY THANK YOU PAGE
==================================================*/

	@media  screen and (max-width: 400px){
		.icon_option_num {
			width: 86% !important;
			font-size: 14px !important;
			padding: 20px 10px !important;
		}
		.icon_option_num_a {
			font-size: 18px !important;
			img {
				width: 20px !important;
			}
		}
	}

	@media  screen and (max-width: 992px){
		.icon_option_num {
			width: 70% !important;
			font-size: 20px !important;
		}
	}

	@media  screen and (max-width: 576px){
		.Thank_conSec{
			h1{
				font-size: 24px !important;
			}// h1
			h3{
				font-size: 18px !important;
			}
			h4{
				font-size: 18px !important;
			}
			h2{
				font-size: 22px !important;
			}
		}
		
		.icon_option_num {
			font-size: 16px !important;
			width: 94% !important;
			padding: 20px 10px !important;
		}
		.icon_option_num_a {
			font-size: 18px !important;
			img {
			width: 18px !important;
			}
		}
	}
}

/*================================================
06 - THANKYOU PAGE DESIGN SECTION
==================================================*/


/*================================================
06 - FOOTER SECTION
==================================================*/

footer {
    background-color: #ffffff;
    color: #000000;
    position: relative;
    width: 100%;
	font-family: 'Roboto', sans-serif !important;
	padding:  70px 0 50px;
    bottom: 0;
	padding-top: 40% !important;
	// box-shadow: -5px -12px 34px -4px rgba(0,0,0,.1);
    p {
		font-size: 14px;
		a.click_link {
			text-decoration: none;
			color: #00f !important;
		}
		a{
			text-decoration: none;
			color: #000000;
		}
    }// FOOTER P
}//FOOTER

/*================================================
	MEDIA QUERY
==================================================*/

@media  screen and (min-width: 768px){
	.txt_block{
		font-size: 1.125rem !important;
		line-height: 1.75rem !important;
	}//txt_block
}

@media  screen and (max-width: 768px){
	.main_contSec{
		h1{
			font-size: 20px !important;
		}// h1
		div{
			img {
				width: 100% !important;
			}
		}// DIV 
	}
}

@media  screen and (max-width: 480px){
footer {
	text-align: center;
	padding-top: 50% !important;
	p {
		font-size: 12px !important;
	}
}//FOOTER
}

@media screen and (max-width: 576px) {
		.form_sec {
			h2 {
				font-size: 22px !important;
				margin-bottom: 30px;
			}
			h5 {
				font-size: 18px !important;
			}
			.icon_option {
				font-size: 16px !important;
			}
		}

}



@media  screen and (max-width: 400px){
	footer {
		text-align: center;
		padding-top: 80% !important;
		p {
			font-size: 10px !important;
		}
	}//FOOTER
}
}